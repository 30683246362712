<template>
  <div class="delivery">
    <a-form class="delivery__card" name="advanced_search">
      <div class="filterFields">
        <a-form-item label="Статус" class="filterFormItems">
          <a-select placeholder="Выберите статус" class="selects">
            <a-select-option value="default">Готов к выдаче.</a-select-option>
            <a-select-option value="success">Доставлено</a-select-option>
            <a-select-option value="processing">В пути с ЦС</a-select-option>
            <a-select-option value="warning">На складе</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Наименование ЮЛ" class="filterFormItems">
          <a-input
            style="margin-bottom: 5px"
            placeholder="Наименование ЮЛ"
          ></a-input>
        </a-form-item>
        <a-form-item label="ИНН" class="filterFormItems">
          <a-input style="margin-bottom: 5px" placeholder="ИНН"></a-input>
        </a-form-item>
      </div>
      <div class="buttons">
        <a-button type="primary">Фильтр</a-button>
        <a-button>Очистить</a-button>
      </div>
    </a-form>
    <div class="top-buttons">
      <a-button-group>
        <a-tooltip placement="topLeft" title="Загрузить">
          <a-button icon="download" />
        </a-tooltip>
        <a-tooltip placement="topLeft" title="Печать">
          <a-button icon="printer" />
        </a-tooltip>
      </a-button-group>
    </div>

    <div class="delivery-page content-container">
      <a-table
        bordered
        class="ordertable"
        :columns="columns"
        :data-source="data"
        :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
      >
        <span slot="orderStatus" slot-scope="orderStatus">
          <a-badge :status="orderStatus" />
          <span v-if="orderStatus === 'default'">Готов к выдаче.</span>
          <span v-else-if="orderStatus === 'success'">Доставлено</span>
          <span v-else-if="orderStatus === 'processing'">В пути с ЦС</span>
          <span v-else-if="orderStatus === 'warning'">На складе</span>
        </span>

        <span slot="operation" slot-scope="text, record" class="action">
          <a-button
            v-if="record.orderStatus !== 'finished'"
            type="primary"
            shape="circle"
            icon="check"
            size="small"
            ghost
          />
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Номер",
    dataIndex: "number",
    key: "number",
    width: 80,
  },
  {
    title: "Наименование",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
  },
  {
    title: "Сумма",
    dataIndex: "sum",
    key: "sum",
    ellipsis: true,
  },
  {
    title: "Количество позиций",
    dataIndex: "positionNumber",
    key: "positionNumber",
    ellipsis: true,
  },
  {
    title: "Статус",
    dataIndex: "orderStatus",
    key: "orderStatus",
    ellipsis: true,
    scopedSlots: { customRender: "orderStatus" },
  },
  {
    title: "Завершен",
    dataIndex: "finished",
    key: "finished",
    align: "center",
    fixed: "right",
    ellipsis: true,
    scopedSlots: { customRender: "operation" },
  },
]

const data = [
  {
    key: "1",
    date: "20.05.2020",
    number: 1711,
    name: "Кузовной - Петров А.А.",
    sum: 67312.0,
    positionNumber: 12,
    orderStatus: "default",
  },
  {
    key: "2",
    date: "20.05.2020",
    number: 1712,
    name: "На склад",
    sum: 1250.0,
    positionNumber: 3,
    orderStatus: "success",
  },
  {
    key: "3",
    date: "21.05.2020",
    number: 1715,
    name: "ТО - Патрикеева С.А.",
    sum: 12070.0,
    positionNumber: 7,
    orderStatus: "processing",
  },
  {
    key: "4",
    date: "22.05.2020",
    number: 1716,
    name: "Магазин - №1516",
    sum: 3500.0,
    positionNumber: 1,
    orderStatus: "processing",
  },
  {
    key: "5",
    date: "22.05.2020",
    number: 1718,
    name: "На склад",
    sum: 16235.0,
    positionNumber: 10,
    orderStatus: "warning",
  },
]

export default {
  name: "Delivery",
  data() {
    return {
      data,
      columns,
    }
  },
}
</script>

<style scoped lang="scss">
.ordertable {
  ::v-deep .ant-table-tbody > tr {
    cursor: pointer;
  }
}

.delivery {
  &__card {
    margin: 10px 0;
    padding: 24px;

    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    .ant-form-item {
      display: flex;

      &-control-wrapper {
        flex: 1;
      }
    }
  }
}

#advanced-search {
  .ant-form {
    max-width: none;
  }

  .search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    padding: 20px 40px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  > button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

.top-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 -5px;
  > button {
    &:first-child {
      margin-right: 15px;
    }
  }
}

.filterFields {
  display: flex;
  flex-direction: row;
}

.filterFormItems {
  display: block;
  margin-right: 20px;
}

.selects {
  width: 200px;
}
</style>
